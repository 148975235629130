













import { Component, Vue } from 'vue-property-decorator';
import Authenticated from '@/components/Authenticated.vue';

@Component({
  components: {
    authenticated: Authenticated,
  },
})
export default class MainAdmin extends Vue {
  selected = 0;

  tabs: any[] = [
    {
      id: 0,
      text: 'Post',
      route: '/admin/post',
    },
    {
      id: 1,
      text: 'Board',
      route: '/admin/board',
    },
  ];

  created() {
    if (this.$route.path.includes(this.tabs[1].route)) this.selected = 1;
  }

  isSelectedLi(id: number): string {
    if (this.selected === id) {
      return '-mb-px mr-1';
    }
    return 'mr-1';
  }

  isSelectedA(id: number): string {
    if (this.selected === id) {
      return 'bg-white inline-block py-2 px-4 text-blue-700 font-semibold '
        + 'border-l border-t border-r rounded-t';
    }
    return 'bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold';
  }
}
